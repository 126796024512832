module.exports = {
  messages: {
    ADVERTISING_DIGITAL_SERVICE_ACT_HEADLINE: 'Why am I seeing this ad?',
    ADVERTISING_DIGITAL_SERVICE_ACT_POP_UP_FOOTER_CTA: 'Learn more',
    ADVERTISING_DIGITAL_SERVICE_ACT_POP_UP_FOOTER_MESSAGE:
      'about targeting criteria.',
    ADVERTISING_DIGITAL_SERVICE_ACT_POP_UP_MESSAGE:
      "You're seeing this ad because you meet at least one of the advertiser's targeting criteria.",
    ADVERTISING_LEAD_AD_FORM_CHECKBOX_ERROR: 'Please accept to proceed.',
    CARDS_SECTION_DESCRIPTION: 'Criteria people often look for:',
    CARDS_SECTION_REGISTRATION_BUTTON_TEXT: 'Register now',
    CARDS_SECTION_REGISTRATION_TEXT: 'Join XING for even more search criteria.',
    CARDS_SECTION_TITLE: 'Discover jobs the smart way',
    CAREER_HUB_TEASER_CARD_DESCRIPTION:
      'Read interesting articles and get advice from seasoned experts and Insiders.',
    CAREER_HUB_TEASER_CARD_TITLE: 'Visit the XING career guide',
    COMPANIES_SECTION_SUBTITLE:
      'Discover companies that are just right for you.',
    COMPANIES_SECTION_TITLE: 'Top Companies on XING',
    CP_HIRING: 'Hiring',
    'CP_TOP_COMPANIES_CARD_BUTTON.one': 'View {value} job',
    'CP_TOP_COMPANIES_CARD_BUTTON.other': 'View {value} jobs',
    CP_TOP_COMPANIES_CARD_HIGHLIGHT:
      '{percentage}% recommendation rate on kununu',
    CRATE_LOSP_CAREER_HUB_CARD_DESCRIPTION:
      'Read interesting articles and get advice from seasoned experts and Insiders.',
    CRATE_LOSP_CAREER_HUB_CARD_TITLE: 'Visit the Career Guide',
    CRATE_LOSP_COMPANIES_SECTION_SUBTITLE:
      'Popular employers with vacancies to fill',
    CRATE_LOSP_COMPANIES_SECTION_TITLE: 'Over 270,000 employers hiring',
    CRATE_LOSP_CV_CARD_DESCRIPTION:
      'Make a great first impression and land the interviews you want.',
    CRATE_LOSP_CV_CARD_TITLE: 'Build a professional CV in minutes',
    CRATE_LOSP_PROFILE_CARD_DESCRIPTION:
      'Create a profile, set your job preferences, and watch the offers come to you!',
    CRATE_LOSP_PROFILE_CARD_TITLE: 'Let the jobs come to you!',
    CRATE_LOSP_RECRUITERS_SECTION_SUBTITLE:
      'Recruiters looking for you and your skill set',
    CRATE_LOSP_RECRUITERS_SECTION_TITLE: 'Over 20,000 recruiters hiring',
    CRATE_LOSP_REGISTER_BUTTON: 'Create profile',
    CRATE_LOSP_REGISTER_WITH_GOOGLE_BUTTON: 'Continue with Google',
    DESCRIPTION_CARD_1: "Work where's best for you.",
    DESCRIPTION_CARD_2: "Work whenever's best for you.",
    DESCRIPTION_CARD_3: 'See where your skills match.',
    DESCRIPTION_CARD_4: 'Earn the salary you deserve.',
    DESCRIPTION_CARD_5: 'Free up time for other things in your life.',
    DESCRIPTION_CARD_6: 'Start small or if you want to earn a little extra.',
    'DISCO_OBJECTS_FOLLOWER.one': 'follower',
    'DISCO_OBJECTS_FOLLOWER.other': 'followers',
    'DISCO_OBJECTS_FOLLOWER.zero': 'followers',
    ERROR_REGISTER_EMAIL_MAX_CHANGE:
      'You can change your e-mail address up to 10 times.',
    JOBS_FIND_JOBS_FLEXITIME_OPTION_FLEXITIME: 'Flexitime',
    JOBS_FIND_JOBS_HEADER_TITLE: 'Find the right job for you.',
    JOBS_FIND_JOBS_PART_TIME_OPTION_PART_TIME: 'Part-time',
    JOBS_FIND_JOBS_RECRUITERS_LINK: 'Recruiter? <a>Post your job here</a>',
    JOBS_FIND_JOBS_REMOTE_OPTION_FULL_REMOTE: 'Remote',
    JOBS_FIND_JOBS_SEARCH_BUTTON: 'Find jobs',
    JOBS_FIND_JOBS_SEARCH_LOCATION_PLACEHOLDER: 'Location',
    JOBS_FIND_JOBS_SEARCH_PLACEHOLDER: 'Enter a job title',
    JOBS_FIND_JOBS_SEARCH_RADIUS_PLACEHOLDER: 'Radius',
    JOBS_FIND_JOBS_TITLE: 'Find jobs',
    JOBS_JOB_TEASER_SAVE: 'Save job',
    JOBS_JOB_TEASER_SAVED: 'Saved',
    JOBS_LANDING_LO_MARKETING_SEC_ONE_TEXT_ONE: 'Over 1 million jobs',
    JOBS_LANDING_LO_MARKETING_SEC_ONE_TEXT_THREE: 'Job search webinars',
    JOBS_LANDING_LO_MARKETING_SEC_ONE_TEXT_TWO: 'More than 21 million members',
    JOBS_RECENT_SEARCH_EMPTY: 'All jobs',
    JOBS_SEARCH_AI_ENTRYPOINT_BANNER: 'New: Find your next job with AI',
    JOBS_SEARCH_AI_ENTRYPOINT_FLAG_DESKTOP: 'Try now',
    JOBS_SEARCH_AI_ENTRYPOINT_FLAG_MOBILE: 'Try now',
    JOBS_SEARCH_AI_FLAG: 'AI-enhanced',
    JOBS_SEARCH_AI_HEADLINE: 'Welcome to a new breed of job search',
    JOBS_SEARCH_AI_INPUT_PLACEHOLDER_1: 'Describe your ideal job…',
    JOBS_SEARCH_AI_INPUT_PLACEHOLDER_10: 'Include your skills and experience',
    JOBS_SEARCH_AI_INPUT_PLACEHOLDER_2:
      'Enter a description in full sentences…',
    JOBS_SEARCH_AI_INPUT_PLACEHOLDER_3: 'Outline the jobs you’d like to do…',
    JOBS_SEARCH_AI_INPUT_PLACEHOLDER_4: 'List ideas for your next career step…',
    JOBS_SEARCH_AI_INPUT_PLACEHOLDER_5:
      'Job title, career level, salary expectations',
    JOBS_SEARCH_AI_INPUT_PLACEHOLDER_6: 'Everything that’s important to you',
    JOBS_SEARCH_AI_INPUT_PLACEHOLDER_7:
      'The more specific you can be, the better',
    JOBS_SEARCH_AI_INPUT_PLACEHOLDER_8:
      'Part-time or full-time, work from home, in the office, or both',
    JOBS_SEARCH_AI_INPUT_PLACEHOLDER_9: 'Avoid using just keywords',
    JOBS_SEARCH_AI_PLACEHOLDER_1: 'Describe your dream job. Go wild!',
    JOBS_SEARCH_AI_PLACEHOLDER_2:
      'Every bit of detail helps improve your results',
    JOBS_SEARCH_AI_PLACEHOLDER_3:
      'Add your preferred job title, level, industry, etc.',
    JOBS_SEARCH_AI_PLACEHOLDER_4:
      'Full-time or part-time? Remote or in an office?',
    JOBS_SEARCH_AI_PLACEHOLDER_5: 'Include your skills and strengths',
    JOBS_SEARCH_AI_PLACEHOLDER_6: 'And how much you want to earn',
    JOBS_SEARCH_AI_PLACEHOLDER_7:
      "Play around with your description until you're happy with it",
    JOBS_SEARCH_AI_SWIMP_SUGGESTION: 'Search with my profile',
    JOBS_SEARCH_AI_SWIMP_SUGGESTION_SUBTITLE:
      'Discover your next step with XING AI',
    JOBS_SEARCH_AI_SWIMS_SUGGESTION: 'I want to change careers',
    JOBS_SEARCH_AI_SWIMS_SUGGESTION_SUBTITLE:
      'Browse entirely new career paths based on your skills thanks to XING AI',
    JOBS_SEARCH_LOCATION_NO_RADIUS: 'No radius',
    LEBENSLAUF_TEASER_CARD_DESCRIPTION:
      'Create a PDF file that shines and gets you interviews.',
    LEBENSLAUF_TEASER_CARD_TITLE: 'Build a professional CV',
    LOGGED_OUT_STARTPAGE_META_DESCRIPTION_JOB_SEARCH:
      'Over 1 million jobs ✓ 20,000 recruiters ✓ Salary ranges ✓ Employer reviews. Set your job preferences and do your XING!',
    LOGGED_OUT_STARTPAGE_META_TITLE_JOB_SEARCH:
      'Find the right job for you. Or get found!',
    LOSP_JOBS_SECTION_SUBTITLE:
      'Browse the most-popular vacancies from the past week.',
    LOSP_JOBS_SECTION_TITLE: 'Trending jobs',
    LOSP_KEYWORDS_ACCOUNTANT: '🧮 Accountant',
    LOSP_KEYWORDS_ADMINISTRATION: '🏫 Administration',
    LOSP_KEYWORDS_ANALYST: '📈 Data analyst',
    LOSP_KEYWORDS_BANKER: '💸 Banker',
    LOSP_KEYWORDS_CALLCENTER: '📞 Call centre jobs',
    LOSP_KEYWORDS_CAREERCHANGER: '👣 Career changer',
    LOSP_KEYWORDS_CARETAKER: '👨‍🔧 Facility manager',
    LOSP_KEYWORDS_CONTROLLER: '🎚️ Controller',
    LOSP_KEYWORDS_DESIGN: '🎨 Design jobs',
    LOSP_KEYWORDS_FLIGHTATTENDANT: '🛫 Flight attendant',
    LOSP_KEYWORDS_FORKLIFT: '👷 Forklift driver',
    LOSP_KEYWORDS_FOUNDATION: '🏛️ Foundation',
    LOSP_KEYWORDS_FREELANCE: '👨🏻‍💻 Freelance jobs',
    LOSP_KEYWORDS_HEALTH: '🥕 Health manager',
    LOSP_KEYWORDS_LOGISTICS: '📦 Logistics jobs',
    LOSP_KEYWORDS_MARKETING: '📣 Marketing jobs',
    LOSP_KEYWORDS_MUSEUM: '🖼️ Museum jobs',
    LOSP_KEYWORDS_NURSERY: '👶 Nursery teacher',
    LOSP_KEYWORDS_OFFICECLERK: '👩‍💻 Office clerk',
    LOSP_KEYWORDS_PROJECTMANAGER: '🤹 Project manager',
    LOSP_KEYWORDS_PSYCHOLOGIST: '🛋️ Psychologist',
    LOSP_KEYWORDS_SALES: '💼 Field sales representative',
    LOSP_KEYWORDS_SALES2: '💰 Sales jobs',
    LOSP_KEYWORDS_SECURITY: '👮🏻 Security officer',
    LOSP_KEYWORDS_SOCIALMEDIA: '🤳 Social media manager',
    LOSP_KEYWORDS_SOCIALWORKER: '👨‍👧‍👦 Social worker',
    LOSP_KEYWORDS_SOCIALWORKER2: '❤️‍🔥 Social worker',
    LOSP_KEYWORDS_SUSTAINABILITY: '🌿 Sustainability jobs',
    LOSP_KEYWORDS_TRUCK: '🚚 Lorry driver',
    LOSP_KEYWORDS_WORKINGSTUDENT: '🧑🏽‍🎓 Working student',
    LOSP_PREFERENCES_EXPERIMENT_CITIES_BERLIN: 'Berlin',
    LOSP_PREFERENCES_EXPERIMENT_CITIES_BUTTON: 'Cities',
    LOSP_PREFERENCES_EXPERIMENT_CITIES_HAMBURG: 'Hamburg',
    LOSP_PREFERENCES_EXPERIMENT_CITIES_HEADLINE: "What's your preferred city?",
    LOSP_PREFERENCES_EXPERIMENT_CITIES_MUNICH: 'Munich',
    LOSP_PREFERENCES_EXPERIMENT_EMPTY_POPUP_BODY:
      'Selecting at least one job preference helps us provide you with matching job vacancies.',
    LOSP_PREFERENCES_EXPERIMENT_EMPTY_POPUP_BUTTON: 'Got it',
    LOSP_PREFERENCES_EXPERIMENT_EMPTY_POPUP_HEADLINE:
      'Please select at least one job preference',
    LOSP_PREFERENCES_EXPERIMENT_HEADLINE: 'Get found by recruiters',
    LOSP_PREFERENCES_EXPERIMENT_LEVEL_BUTTON: 'Career level',
    LOSP_PREFERENCES_EXPERIMENT_LEVEL_ENTRY: 'Entry level',
    LOSP_PREFERENCES_EXPERIMENT_LEVEL_HEADLINE:
      "What's your preferred career level?",
    LOSP_PREFERENCES_EXPERIMENT_LEVEL_MANAGER: 'Manager/Supervisor',
    LOSP_PREFERENCES_EXPERIMENT_LEVEL_PROFESSIONAL: 'Professional',
    LOSP_PREFERENCES_EXPERIMENT_OTHERS_DISCIPLINES: 'Disciplines',
    LOSP_PREFERENCES_EXPERIMENT_OTHERS_HEADLINE:
      'What other job preferences do you have?',
    LOSP_PREFERENCES_EXPERIMENT_OTHERS_INDUSTRIES: 'Industries',
    LOSP_PREFERENCES_EXPERIMENT_OTHERS_JOBTITLE: 'Job titles',
    LOSP_PREFERENCES_EXPERIMENT_OTHERS_WORKING_HOURS: 'Working hours',
    LOSP_PREFERENCES_EXPERIMENT_PLACE_BUTTON: 'Your workplace',
    LOSP_PREFERENCES_EXPERIMENT_PLACE_HEADLINE:
      'Where would you like to work in the future?',
    LOSP_PREFERENCES_EXPERIMENT_PLACE_HOMEOFFICE: '100% from home',
    LOSP_PREFERENCES_EXPERIMENT_PLACE_ONSITE: 'In the office',
    LOSP_PREFERENCES_EXPERIMENT_SALARY_BUTTON: 'Salary',
    LOSP_PREFERENCES_EXPERIMENT_SALARY_EIGHTY: '€80,000',
    LOSP_PREFERENCES_EXPERIMENT_SALARY_FORTY: '€40,000',
    LOSP_PREFERENCES_EXPERIMENT_SALARY_FORTY_FIVE: '€45,000',
    LOSP_PREFERENCES_EXPERIMENT_SALARY_HEADLINE:
      'What are your minimum salary expectations?',
    LOSP_PREFERENCES_EXPERIMENT_SALARY_SIXTY: '€60,000',
    LOSP_PREFERENCES_EXPERIMENT_SALARY_THIRTY: '€30,000',
    LOSP_PREFERENCES_EXPERIMENT_SAVE: 'Save preferences',
    LOSP_PREFERENCES_EXPERIMENT_SAVE_POPUP_BODY:
      'Great companies and over 20,000 recruiters are actively looking for new hires on XING. Get found via your XING profile and receive job recommendations that match your preferences.',
    LOSP_PREFERENCES_EXPERIMENT_SAVE_POPUP_BUTTON: 'Register now',
    LOSP_PREFERENCES_EXPERIMENT_SAVE_POPUP_HEADLINE:
      'Let the jobs come to you.',
    LOSP_PREFERENCES_EXPERIMENT_SEE_MORE: 'Show more',
    LOSP_PREFERENCES_EXPERIMENT_SUBHEADLINE:
      'Save yourself the hassle of job searching by setting preferences.',
    LOSP_PREFERENCES_EXPERIMENT_WORKPLACE_HOME_OFFICE: '100% from home',
    LOSP_PREFERENCES_EXPERIMENT_WORKPLACE_MOSTLY_HOME: 'Mostly from home',
    LOSP_PREFERENCES_EXPERIMENT_WORKPLACE_OFFICE: 'In the office',
    LOSP_PREFERENCES_EXPERIMENT_WORKPLACE_PARTLY_HOME: 'Partly from home',
    LOSP_RECRUITERS_SECTION_COMPANY_CTA: 'Show jobs',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_AIRBUS:
      '80% recommendation rate in the last 2 years',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_AMAZON:
      'Employee benefits - discounts, car park, staff events',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_AOK:
      "Over 1,440 ratings are 'very good'",
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_AUDI:
      '86% of staff are happy with their salary',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_BAHNE_GROTH:
      'Recruiter for positions at flaschenpost headquarters',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_BASF:
      'Frequently confirmed benefits - flexitime, work from home, company pension',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_BMW:
      'Frequently confirmed benefits - canteen, company pension, discounts',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_BOSCH:
      'Frequently confirmed benefits - flexitime, company doctor',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_CAROLA_LUDEWIG:
      'Hiring security professionals at various levels',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_DEUTSCHE_BAHN:
      '74% recommendation rate in the last 2 years',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_DEUTSCHE_BANK:
      'Frequently confirmed benefits - flexitime, company pension',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_DEUTSCHE_POST:
      'Over 12,250 employee and applicant reviews',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_DM:
      '70% recommendation rate in the last 2 years',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_DUERENHOFF_GMBH:
      "Top 10 kununu rating: Germany's most family-friendly employers",
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_FATMA_KOC:
      'Active sourcer for the financial Services sector',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_FINJA_STRUBER:
      'Recruiter at the ROSSMANN headquarters',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_HUSEYIN_YALCIN:
      'Reply rate: Less than 3 hours',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_IKEA:
      'Frequently confirmed benefits - discounts, canteen, company pension',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_ISABEL_STRUSCHKA:
      'Contact person for finance, IT, people, and culture',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_JAN_GOTZE:
      'Hiring IT experts and managers',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_JAN_SCHENGBER:
      'Reply rate: Less than 4 hours',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_JOHANNA_ECKERT:
      'Tax & auditing recruiter',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_JONAS_MASANNECK:
      'Non-tech and life sciences recruiter',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_JULIA_REICHHOFER:
      'Recruiter for all positions for the Schmersal Group.',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_KAUFLAND:
      'Over 2,867 employee and applicant reviews',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_KRISTIN_SEMELKA:
      'Recruiter, including apprenticeships',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_LIDL: 'kununu Top Company 2023',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_MALTE_BALMER:
      'Your contact person for jobs at OTTO',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_MARIA_HEUCKE:
      'Passionate about recruiting #weempowerpeople',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_MEINESTADT_DE:
      '88% recommendation rate in the last 2 years',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_MIRIAM_DJAMBOU:
      'IT and engineering placement',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_OTTOBOCK:
      'Frequently confirmed benefits - flexitime, company doctor',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_PORSCHE:
      '95% of staff are happy with their salary',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_RALPH_OSTERMEIER:
      'Your contact for IT jobs at EDEKA',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_REWE:
      '61% recommendation rate in the last 2 years',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_ROBIN_MAURER:
      'Recruiting & employer branding for three companies',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_ROSSMANN:
      'Over 1,621 employee and applicant reviews',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_SAP:
      '93% recommendation rate in the last 2 years',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_SARAH_BAUDZUS:
      'Inpatient and outpatient nursing care for over 10 years',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_SIEMENS:
      '67% confirm flexitime as a benefit',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_STEFANIE_KLOCK:
      'Sales and marketing expert placement',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_VANESSA_STRAUCH:
      'Transport and logistics recruiter',
    LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_VERONIKA_GERSDORF:
      'Recruiter for blue and white collar vacancies',
    LOSP_RECRUITERS_SECTION_RECRUITER_CTA: 'View profile',
    LOSP_SEARCH_INSPIRATION_CAREER_LEVEL: 'Your career level',
    LOSP_SEARCH_INSPIRATION_DESCRIPTION_CARD_1: 'Work where’s best for you.',
    LOSP_SEARCH_INSPIRATION_DESCRIPTION_CARD_5:
      'Free up time for other things in your life.',
    LOSP_SEARCH_INSPIRATION_DESCRIPTION_CARD_6:
      'Start small or if you want to earn a little extra.',
    LOSP_SEARCH_INSPIRATION_PREFERENCES_CAREER_LEVEL_HEADLINE:
      'What’s your preferred career level?',
    LOSP_SEARCH_INSPIRATION_PREFERENCES_DESCRIPTION:
      'Pick what’s important to you for better job matches.',
    LOSP_SEARCH_INSPIRATION_PREFERENCES_DESCRIPTION_2: 'It’s quick and simple!',
    LOSP_SEARCH_INSPIRATION_PREFERENCES_MODAL_SUBHEADLINE:
      'Pick what’s important to you for better job matches. It’s quick and simple!',
    LOSP_SEARCH_INSPIRATION_PREFERENCES_MODAL_TITLE: 'Set your job preferences',
    LOSP_SEARCH_INSPIRATION_PREFERENCES_SALARY_HEADLINE:
      'What are your minimum salary expectations?',
    LOSP_SEARCH_INSPIRATION_PREFERENCES_TITLE: 'Set your job preferences',
    LOSP_SEARCH_INSPIRATION_PREFERENCES_WORKPLACE_HEADLINE:
      'Where would you like to work?',
    LOSP_SEARCH_INSPIRATION_SALARY: 'Salary expectations',
    LOSP_SEARCH_INSPIRATION_SET_PREFERENCES: 'Save preferences',
    LOSP_SEARCH_INSPIRATION_TITLE_CARD_1: 'Remote jobs',
    LOSP_SEARCH_INSPIRATION_TITLE_CARD_3: 'Jobs for career changers',
    LOSP_SEARCH_INSPIRATION_WORKPLACE: 'Your workplace',
    LOSP_SEO_SECTION_COMPANIES_DIRECTORY: 'Companies A to Z',
    LOSP_SEO_SECTION_HEADER_DIRECTORIES: 'Directories',
    LOSP_SEO_SECTION_HEADER_JOB_ROLES: 'Job roles',
    LOSP_SEO_SECTION_HEADER_TOP_LOCATIONS: 'Top locations',
    LOSP_SEO_SECTION_HEADER_TOP_SKILLS: 'Top skills',
    LOSP_SEO_SECTION_JOBS_DIRECTORY: 'Jobs A to Z',
    LOSP_SEO_SECTION_PEOPLE_DIRECTORY: 'Members A to Z',
    NATIVE_ADS_DOT_MENU_REPORT_OBJECT: 'Report this ad',
    NATIVE_ADS_LEARN_MORE_BUTTON: 'Learn more',
    NATIVE_ADS_SPONSORED_LABEL: 'Sponsored',
    PROFILE_TEASER_CARD_DESCRIPTION:
      'It’s free and unlocks countless opportunities.',
    PROFILE_TEASER_CARD_TITLE: 'Set up a XING profile',
    QR_CODE_BANNER_SUBTITLE: 'Because a job is just a XING away.',
    QR_CODE_BANNER_TITLE: 'Download the XING app',
    RECRUITERS_SECTION_FIRST_SUBTITLE:
      'Create a profile and find the job that’s right for you.',
    RECRUITERS_SECTION_FIRST_TITLE: 'XING - The Jobs Network',
    RECRUITERS_SECTION_FLAG_COMPANIES: 'Hiring',
    RECRUITERS_SECTION_FLAG_RECRUITERS: 'Hiring',
    RECRUITERS_SECTION_REGISTER_BUTTON: 'Create profile',
    RECRUITERS_SECTION_REGISTER_WITH_GOOGLE_BUTTON: 'Continue with Google',
    RECRUITERS_SECTION_SECOND_SUBTITLE:
      'Get in touch with over 20,000 recruiters from popular employers.',
    RECRUITERS_SECTION_SECOND_TITLE: 'Make the right connections',
    REGISTER_SECTION_DATE_MARK: 'Today',
    REGISTER_SECTION_FIRST_TAG: 'Your salary expectations',
    REGISTER_SECTION_HEADLINE: '4 new requests',
    REGISTER_SECTION_SECOND_TAG: 'Your industry',
    REGISTER_SECTION_THIRD_TAG: 'Your preferred career level',
    SEO_SECTION_TITLE: 'Job search suggestions',
    TITLE_CARD_1: 'Jobs where you can work remotely',
    TITLE_CARD_2: 'Jobs offering flexitime',
    TITLE_CARD_3: 'Jobs in different fields',
    TITLE_CARD_4: 'Jobs offering top salaries',
    TITLE_CARD_5: 'Part-time jobs',
    TITLE_CARD_6: 'Mini-jobs',
    WELCOME_APP_MINIREG_ERROR_EMAIL:
      'Please check the e-mail address you entered.',
    WELCOME_APP_MINIREG_ERROR_FIRST_NAME: 'Please enter your first name here.',
    WELCOME_APP_MINIREG_ERROR_LAST_NAME: 'Please enter your last name here.',
    WELCOME_APP_MINIREG_ERROR_PASSWORD_EMAIL:
      'For your own security you should choose a password different to your e-mail address.',
    WELCOME_APP_MINIREG_ERROR_PASSWORD_LAST_NAME:
      'For your own security you should choose a password different to your last name.',
    WELCOME_APP_MINIREG_ERROR_PASSWORD_TOO_LONG:
      'Please choose a password containing no more than 1,024 characters.',
    WELCOME_APP_MINIREG_ERROR_PHONE: 'Please check your phone number.',
    WELCOME_APP_MINIREG_ERROR_TANDC:
      'Please confirm that you accept the Terms & Conditions and the Privacy Policy.',
    WELCOME_COMPLETION_HEADLINE: 'Welcome to XING!',
    WELCOME_COMPLETION_PAGE_META_TITLE: 'Welcome to XING! | XING',
    WELCOME_COMPLETION_SUBLINE:
      'Just provide a few details to complete your registration:',
    WELCOME_COMPLETION_SUBLINE_B:
      'Your profile is ready to go. All we need now is your name:',
    WELCOME_COMPLETION_SUBLINE_C:
      'Find familiar faces on XING. Just enter your name now:',
    WELCOME_COMPLETION_SUBLINE_D:
      'Just add your name now to start connecting with friends and colleagues:',
    WELCOME_COMPLETION_SUBLINE_E:
      "We're your professional companion. Who are you?",
    WELCOME_CONFIRMATION_DEFAULT_BOUNCED_BODY:
      "Please check your e-mail address again. If you're sure it's right, get in touch with our {link} team.",
    WELCOME_CONFIRMATION_DEFAULT_BOUNCED_CTA: 'Change e-mail address',
    WELCOME_CONFIRMATION_DEFAULT_BOUNCED_HEADER: 'E-mail undeliverable',
    WELCOME_CONFIRMATION_DEFAULT_BOUNCED_SUPPORT_LINK_TEXT: 'support',
    WELCOME_CONFIRMATION_DEFAULT_BUTTON: 'Not received an e-mail?',
    WELCOME_CONFIRMATION_DEFAULT_COPY_1: 'Confirm your account now:',
    WELCOME_CONFIRMATION_DEFAULT_COPY_1_C: 'We just sent a link to {email}.*',
    WELCOME_CONFIRMATION_DEFAULT_COPY_1_D: 'We just sent a link to {email}.*',
    WELCOME_CONFIRMATION_DEFAULT_COPY_2: 'We just sent a link to {email}.*',
    WELCOME_CONFIRMATION_DEFAULT_COPY_2_C:
      'Click on it to confirm your e-mail address.',
    WELCOME_CONFIRMATION_DEFAULT_COPY_2_D:
      'Click on it to complete your registration.',
    WELCOME_CONFIRMATION_DEFAULT_HEADLINE: 'Almost there!',
    WELCOME_CONFIRMATION_DEFAULT_HEADLINE_C: 'You have mail:',
    WELCOME_CONFIRMATION_DEFAULT_HEADLINE_D: 'Confirm registration',
    WELCOME_CONFIRMATION_DEFAULT_SUFFIX:
      "* E-mail not arrived? Please check your spam folder. If you're using Gmail, please also check your 'Advertising' and 'Social networks' folders.",
    WELCOME_CONFIRMATION_EDIT_EMAIL_BUTTON: 'Send confirmation e-mail',
    WELCOME_CONFIRMATION_EDIT_EMAIL_COPY: 'If not, just update it below:',
    WELCOME_CONFIRMATION_EDIT_EMAIL_HEADLINE: 'Is this your e-mail address?',
    WELCOME_CONFIRMATION_EDIT_EMAIL_LABEL: 'E-mail',
    WELCOME_CONFIRMATION_EDIT_EMAIL_REQUEST_ERROR:
      'Sorry, something went wrong. Please try again.',
    WELCOME_CONFIRMATION_EMAIL_RESENT_BUTTON: 'Still not received an e-mail?',
    WELCOME_CONFIRMATION_EMAIL_RESENT_COPY_1:
      'We just sent a new confirmation link to {email}',
    WELCOME_CONFIRMATION_EMAIL_RESENT_COPY_2:
      'Please click on the link in your e-mail to complete registration. ',
    WELCOME_CONFIRMATION_EMAIL_RESENT_HEADLINE: 'E-mail sent!',
    WELCOME_CONFIRMATION_PAGE_META_TITLE: 'Activate your account | XING',
    WELCOME_GDPR_IMG_ALT: 'Privacy at XING is GDPR-compliant',
    WELCOME_INVITATION_HEADLINE:
      '{firstname} {lastname} wants you to join XING.',
    WELCOME_INVITATION_SUBLINE:
      'Register now to add {firstname} as your first XING contact.',
    WELCOME_LANGUAGE_PAGE_META_TITLE: 'Choose language | XING',
    WELCOME_LANGUAGE_SELECT_ENGLISH_LABEL: 'English',
    WELCOME_LANGUAGE_SELECT_FRENCH_LABEL: 'Français',
    WELCOME_LANGUAGE_SELECT_GERMAN_LABEL: 'Deutsch',
    WELCOME_LANGUAGE_SELECT_HEADLINE: 'Please select your preferred language:',
    WELCOME_LANGUAGE_SELECT_ITALIAN_LABEL: 'Italiano',
    WELCOME_LANGUAGE_SELECT_SPANISH_LABEL: 'Español',
    WELCOME_LOGIN_FORM_EMAIL_REQUIRED_ERROR:
      'Please enter your e-mail address here.',
    WELCOME_LOGIN_FORM_FIRSTNAME_REQUIRED_ERROR:
      'Please enter your first name here.',
    WELCOME_LOGIN_FORM_LASTNAME_REQUIRED_ERROR:
      'Please enter your last name here.',
    WELCOME_LOGIN_FORM_PASSWORD_REQUIRED_ERROR:
      'Please enter your password here.',
    WELCOME_LOGIN_FORM_PASSWORD_REQURED_ERROR:
      'Please enter your password here.',
    WELCOME_LOGIN_FORM_REMEMEMBER_ME_LABEL: 'Remember me',
    WELCOME_LOGIN_FORM_SUBMIT_REQUEST_ERROR:
      'Sorry, something went wrong. Please try again.',
    WELCOME_LOGIN_FORM_USERNAME_REQUIRED_ERROR:
      'Please enter your e-mail address.',
    WELCOME_LOGIN_HEADLINE: 'Welcome back!',
    WELCOME_LOGIN_PASSWORD_LABEL: 'Password',
    WELCOME_LOGIN_PROBLEMS_LOGGING_IN: 'Having problems logging in?',
    WELCOME_LOGIN_SUBMIT_LABEL: 'Log in',
    WELCOME_LOGIN_USERNAME_LABEL: 'E-mail',
    WELCOME_REGISTRATION_CTA_REG_7503_E: 'Create new profile',
    WELCOME_REGISTRATION_CTA_REG_7504_A: 'Continue',
    WELCOME_REGISTRATION_CTA_REG_7504_B: 'Sign up',
    WELCOME_REGISTRATION_CTA_REG_7504_C: 'Sign up now',
    WELCOME_REGISTRATION_CTA_REG_7504_D: 'Create profile',
    WELCOME_REGISTRATION_CTA_REG_7504_E: 'Join XING',
    WELCOME_REGISTRATION_DATAPROTECTION_LABEL: 'Privacy Policy',
    WELCOME_REGISTRATION_DATAPROTECTION_LINK:
      'https://privacy.xing.com/en/privacy-policy',
    WELCOME_REGISTRATION_EMAIL_LABEL: 'E-mail',
    WELCOME_REGISTRATION_FIRSTNAME_LABEL: 'First name',
    WELCOME_REGISTRATION_HEADLINE: 'Get your free XING profile now:',
    WELCOME_REGISTRATION_HEADLINE_REG_7088_B:
      "Sign up now – it's free and only takes 2 minutes!",
    WELCOME_REGISTRATION_HEADLINE_REG_7088_C:
      "Sign up now – it's free and only takes 2 minutes!",
    WELCOME_REGISTRATION_HEADLINE_REG_7088_D:
      "Sign up now – it's free and only takes 2 minutes!",
    WELCOME_REGISTRATION_HEADLINE_REG_7088_E:
      "Sign up now – it's free and only takes 2 minutes!",
    WELCOME_REGISTRATION_HEADLINE_REG_7396_B:
      'Registration is just a few clicks away:',
    WELCOME_REGISTRATION_HEADLINE_REG_7396_C: 'Boost your career:',
    WELCOME_REGISTRATION_HEADLINE_REG_7396_D: 'Get your free XING profile now:',
    WELCOME_REGISTRATION_HEADLINE_REG_7550_CMP:
      'Register now and enjoy Premium free for 30 days:',
    WELCOME_REGISTRATION_IGNORE_SOFT_EMAIL_ERRORS_LABEL:
      'Yes, {email} is the right e-mail address.',
    WELCOME_REGISTRATION_LASTNAME_LABEL: 'Last name',
    WELCOME_REGISTRATION_PASSWORD_LABEL: 'Password',
    WELCOME_REGISTRATION_RECAPTCHA_HEADLINE: 'Nearly done!',
    WELCOME_REGISTRATION_RECAPTCHA_PARAGRAPH:
      "We need to make sure you're a real person to protect you and others from spam.",
    WELCOME_REGISTRATION_SOFT_EMAIL_ERROR:
      'Please check your e-mail address again.',
    WELCOME_REGISTRATION_SUBMIT_LABEL: 'Continue',
    WELCOME_REGISTRATION_TANDC_DESCRIPTION:
      "I accept XING's {tandc} and acknowledge the {dataprotection}.",
    WELCOME_REGISTRATION_TANDC_LABEL: 'GTC',
    WELCOME_REGISTRATION_TIMER_CAPTCHA_BUTTON:
      'Press and hold me for 5 seconds',
    WELCOME_REGISTRATION_TIMER_CAPTCHA_ERROR:
      "That didn't work. Please press and hold the button again for 5 seconds.",
    WELCOME_REGISTRATION_TIMER_CAPTCHA_HEADLINE: 'One last thing',
    WELCOME_REGISTRATION_TIMER_CAPTCHA_PARAGRAPH:
      "To make sure you're a real person and not a bot, please press and hold the button below for 5 seconds.",
    WELCOME_REGISTRATION_VIA_CV_BAD_FILE_ERROR_LABEL:
      'Your file is either too big or in an unsupported format. We recommend PDF files.',
    WELCOME_REGISTRATION_VIA_CV_BUTTON_LABEL: 'Upload CV',
    WELCOME_REGISTRATION_VIA_CV_COMMON_ERROR_LABEL:
      "That didn't work. Please try again.",
    WELCOME_REGISTRATION_VIA_CV_DESCRIPTION_DEFAULT:
      'Submit your CV now and we will provide you with personalized job recommendations.',
    WELCOME_REGISTRATION_VIA_CV_DESCRIPTION_SUCCESS:
      "We've added your CV details to your XING profile. {br}{br} Please make sure everything is okay and add any information that's missing.",
    WELCOME_REGISTRATION_VIA_CV_FILE_INFO_LABEL:
      'Supported formats: PDF, Word (DOCX) or Pages (max. 100 MB)',
    WELCOME_REGISTRATION_VIA_CV_HEADLINE_DEFAULT: 'Let recruiters come to you',
    WELCOME_REGISTRATION_VIA_CV_HEADLINE_SUCCESS: 'Your CV',
    WELCOME_SEARCHPAGE_CTA:
      'Go ahead and find your next job, or connect with friends and colleagues:',
    WELCOME_SEARCHPAGE_HEADLINE: 'Look for jobs and people',
    WELCOME_SEARCHPAGE_JOBS_BUTTON: 'Jobs',
    WELCOME_SEARCHPAGE_JOBS_PLACEHOLDER: 'Enter a job title or keyword',
    WELCOME_SEARCHPAGE_MEMBERS_BUTTON: 'Members',
    WELCOME_SEARCHPAGE_MEMBERS_PLACEHOLDER: 'Name, company or keyword',
    WELCOME_SEARCHPAGE_SEARCH_BUTTON: 'Search',
    WELCOME_SIGNUP_FORM_SUBMIT_REQUEST_ERROR:
      'Sorry, something  went wrong. Please try again.',
    WELCOME_SIGNUP_FORM_VALIDATE_RELOAD_ERROR:
      'Something went wrong. Please enable cookies in your browser and reload the page.',
    WELCOME_SIGNUP_FORM_VALIDATE_REQUEST_ERROR:
      'Sorry, something  went wrong. Please try again.',
    WELCOME_SIGNUP_PAGE_META_TITLE: 'Join now | XING',
    WELCOME_SIGNUP_USP_CONTACTS_COPY:
      'Get found by employers and over 20,000 recruiters.',
    WELCOME_SIGNUP_USP_CONTACTS_TITLE: 'Job offers just for you',
    WELCOME_SIGNUP_USP_FREE_COPY:
      'Basic membership is free, but the opportunities are priceless.',
    WELCOME_SIGNUP_USP_FREE_TITLE: 'Free membership',
    WELCOME_SIGNUP_USP_PRESENCE_COPY:
      'Find just the right job for you on XING.',
    WELCOME_SIGNUP_USP_PRESENCE_TITLE: 'Over 1 million jobs',
    WELCOME_SIGNUP_USP_SECURITY_COPY:
      'Connect with new people and gather inspiration to boost your working life',
    WELCOME_SIGNUP_USP_SECURITY_TITLE: '21 million members',
    WELCOME_SIGNUP_USPS_TITLE: 'XING – The jobs network',
    WELCOME_STARTPAGE_APP_STORE_BADGE_ALT: 'Download on the App Store',
    WELCOME_STARTPAGE_CULTURE_CHECK_BUTTON: 'View culture assessment',
    WELCOME_STARTPAGE_CULTURE_CHECK_IMG_ALT:
      'Graphic for the culture assessment',
    WELCOME_STARTPAGE_CULTURE_CHECK_TEXT:
      'What do you prefer – flat hierarchies or clear instructions? There are many factors covering corporate culture, and you can find out which ones matter most to you. That way, you know what to look for in your next job. Check out the culture assessment now:',
    WELCOME_STARTPAGE_CULTURE_CHECK_TITLE: 'Find the right workplace for you',
    WELCOME_STARTPAGE_CULTURE_COMPASS_BUTTON: 'View Culture Compass',
    WELCOME_STARTPAGE_CULTURE_COMPASS_IMG_ALT:
      'A graphic showing corporate culture with a scale ranging from traditional to modern.',
    WELCOME_STARTPAGE_CULTURE_COMPASS_TEXT:
      "Ever wondered what the people are like at company XYZ? The culture compass shows you what it's like to work there. You'll discover a whole host of insights so you can make an informed decision before applying.",
    WELCOME_STARTPAGE_CULTURE_COMPASS_TITLE: 'Follow the Culture Compass',
    WELCOME_STARTPAGE_DOWNLOAD_APP_CTA: 'Ready for the new XING app?',
    WELCOME_STARTPAGE_FORM_TITLE_REG_7371_01:
      "Sign up now – it's free and only takes 2 minutes:",
    WELCOME_STARTPAGE_FORM_TITLE_REG_7371_02:
      "Sign up now – it's free and only takes 2 minutes:",
    WELCOME_STARTPAGE_FORM_TITLE_REG_7371_03:
      "Sign up now – it's free and only takes 2 minutes:",
    WELCOME_STARTPAGE_FORM_TITLE_REG_7371_04:
      "Sign up now – it's free and only takes 2 minutes:",
    WELCOME_STARTPAGE_GOOGLE_PLAY_BADGE_ALT: 'Get it on Google Play',
    WELCOME_STARTPAGE_HEADER_BUTTON: 'Join now',
    WELCOME_STARTPAGE_HEADER_IMAGE_ALT:
      'Happy woman leaning back and stretching on an office chair',
    WELCOME_STARTPAGE_HEADER_SUBTITLE: 'Do your XING!',
    WELCOME_STARTPAGE_HEADER_TITLE: 'It’s time to be yourself.',
    WELCOME_STARTPAGE_JOBS_DIRECTORY_NAVIGATION_TITLE:
      'Job titles that start with',
    WELCOME_STARTPAGE_JOBS_VERSION_HEADER_BUTTON: 'Register for free',
    WELCOME_STARTPAGE_JOBS_VERSION_HEADER_SUBTITLE:
      "Or register now and we'll do the searching for you: ",
    WELCOME_STARTPAGE_JOBS_VERSION_HEADER_TITLE:
      'Find the right job{br}\nfor you:',
    WELCOME_STARTPAGE_JOBS_VERSION_JOB_FILTER_DOGS_FRIENDLY: 'Dogs welcome',
    WELCOME_STARTPAGE_JOBS_VERSION_JOB_FILTER_FLEXIBILITY: 'Flexitime',
    WELCOME_STARTPAGE_JOBS_VERSION_JOB_FILTER_PART_TIME: 'Part-time',
    WELCOME_STARTPAGE_JOBS_VERSION_JOB_FILTER_REMOTE: 'Remote',
    WELCOME_STARTPAGE_JOBS_VERSION_JOB_INPUT_PLACEHOLDER:
      'Enter a job title or keyword',
    WELCOME_STARTPAGE_JOBS_VERSION_LOCATION_INPUT_PLACEHOLDER: 'Location',
    WELCOME_STARTPAGE_JOBS_VERSION_SEARCH_SUBMIT_BUTTON: 'Search jobs',
    WELCOME_STARTPAGE_JOBS_VERSION_SECTION_ONE_IMG_ALT:
      'Drawing of a person and a CV',
    WELCOME_STARTPAGE_JOBS_VERSION_SECTION_ONE_TEXT:
      "Whether it's a change of city, lifestyle or just wanting something new – on XING it’s all about who you want to be in your working life. Let the real you shine through.",
    WELCOME_STARTPAGE_JOBS_VERSION_SECTION_ONE_TITLE:
      "On XING, you're more than just your CV",
    WELCOME_STARTPAGE_JOBS_VERSION_SECTION_THREE_IMG_ALT:
      "Drawing of a person holding a card showing various peoples' heads",
    WELCOME_STARTPAGE_JOBS_VERSION_SECTION_THREE_TEXT:
      'Over 20,000 recruiters on XING are just a message away. Get in touch with HR, industry and labour market experts, and personally contact recruiters and employees at your dream company. Join us and do your XING!',
    WELCOME_STARTPAGE_JOBS_VERSION_SECTION_THREE_TITLE:
      'Reach out to the right people',
    WELCOME_STARTPAGE_JOBS_VERSION_SECTION_TWO_IMG_ALT:
      'Drawing of a person with various job-specific hats',
    WELCOME_STARTPAGE_JOBS_VERSION_SECTION_TWO_TEXT:
      'Imagine a gallery of amazing jobs where you receive suggestions tailored to your preferences. Find out what you really want from your working life: how do you want to develop? Which employers do you like? What company culture do you want to be part of?',
    WELCOME_STARTPAGE_JOBS_VERSION_SECTION_TWO_TITLE:
      'Over 1 million jobs and countless opportunities',
    WELCOME_STARTPAGE_JOBSFINDER_CTA_BUTTON: 'Register now',
    WELCOME_STARTPAGE_JOBSFINDER_CTA_TEXT:
      'Find out how location and industry affect salaries. With XING.',
    WELCOME_STARTPAGE_JOBSFINDER_IMG_ALT:
      'Woman in an uncomfortable position trying to work',
    WELCOME_STARTPAGE_JOBSFINDER_SUBTITLE: 'Be your true self:',
    WELCOME_STARTPAGE_JOBSFINDER_SUGGESTION_CONSULTING: 'Consulting',
    WELCOME_STARTPAGE_JOBSFINDER_SUGGESTION_IT: 'IT',
    WELCOME_STARTPAGE_JOBSFINDER_SUGGESTION_LOGISTICS: 'Logistics',
    WELCOME_STARTPAGE_JOBSFINDER_SUGGESTION_MARKETING: 'Marketing',
    WELCOME_STARTPAGE_JOBSFINDER_SUGGESTION_MORE_JOBS: 'More industries',
    WELCOME_STARTPAGE_JOBSFINDER_SUGGESTION_PROJECT_MANAGEMENT:
      'Project management',
    WELCOME_STARTPAGE_JOBSFINDER_TITLE: 'Find the right job for you',
    WELCOME_STARTPAGE_KEY_FEATURES_EVENTS: '1.7 million events',
    WELCOME_STARTPAGE_KEY_FEATURES_JOBS: 'Over 250,000 jobs',
    WELCOME_STARTPAGE_KEY_FEATURES_MEMBERS: '21 million members',
    WELCOME_STARTPAGE_KEY_FEATURES_NEWS: 'Daily industry news',
    WELCOME_STARTPAGE_MAIN_CTA_BUTTON: 'Join now for free',
    WELCOME_STARTPAGE_MAIN_CTA_TEXT:
      'XING – the leading online jobs network in German-speaking countries.',
    WELCOME_STARTPAGE_MAIN_DESCRIPTION:
      'XING is your personal career companion. Connect with new people and discover exciting jobs as well as specialist news.',
    WELCOME_STARTPAGE_MAIN_DESCRIPTION_REG_7088_B:
      'Find a job to match your lifestyle.',
    WELCOME_STARTPAGE_MAIN_DESCRIPTION_REG_7088_C:
      "On XING you'll get to know interesting people and share ideas and inspiration.",
    WELCOME_STARTPAGE_MAIN_DESCRIPTION_REG_7088_D:
      "See what's new with your colleagues and discuss the things you're passionate about with like-minded people.",
    WELCOME_STARTPAGE_MAIN_DESCRIPTION_REG_7088_E:
      'Build your very own network of contacts and share your ideas and inspiration with like-minded people.',
    WELCOME_STARTPAGE_MAIN_SUBTITLE_REG_7494:
      'Für Dein Leben. Für Deinen Job. Für Dein Job-Leben.',
    WELCOME_STARTPAGE_MAIN_TITLE: 'XING – Ideas for a new world of work',
    WELCOME_STARTPAGE_MAIN_TITLE_REG_7088_B: 'Find the right job for you',
    WELCOME_STARTPAGE_MAIN_TITLE_REG_7088_C:
      'Achieve more thanks to your network.',
    WELCOME_STARTPAGE_MAIN_TITLE_REG_7088_D: 'Careers are built on networks.',
    WELCOME_STARTPAGE_MAIN_TITLE_REG_7088_E:
      'Meet the right people for your career.',
    WELCOME_STARTPAGE_MAIN_TITLE_REG_7371_01:
      'Find the right job for the right work-life balance.',
    WELCOME_STARTPAGE_MAIN_TITLE_REG_7371_02:
      "Say 'bye' to procrastination and 'hi' to the right job.",
    WELCOME_STARTPAGE_MAIN_TITLE_REG_7371_03:
      'XING – Your professional companion.',
    WELCOME_STARTPAGE_MAIN_TITLE_REG_7371_04:
      'Find the right job for the right work-life balance.',
    WELCOME_STARTPAGE_MAIN_TITLE_REG_7494: 'Weil Du’s Dir vorgenommen hast.',
    WELCOME_STARTPAGE_MAIN_TITLE_REG_7747:
      'It’s time to be yourself.{br}\nDo your XING!',
    WELCOME_STARTPAGE_MEMBER_DIRECTORY_NAVIGATION_TITLE:
      'Members whose last name starts with',
    WELCOME_STARTPAGE_META_DESCRIPTION:
      'XING is your personal career companion. Connect with new people, discover exciting jobs, and gather valuable inspiration for your working life.',
    WELCOME_STARTPAGE_META_DESCRIPTION_JOB_SEARCH:
      '✓ Kultur-Check ✓ Arbeitgeber-Bewertungen ✓ e-Learning ✓ Recruiter-Kontakte - Mach Dein XING für mehr Sinn und Zufriedenheit im Job-Leben',
    WELCOME_STARTPAGE_META_TITLE: 'XING – Ideas for a new world of work',
    WELCOME_STARTPAGE_META_TITLE_JOB_SEARCH:
      'XING - Find the right job for you',
    WELCOME_STARTPAGE_OG_SITENAME: 'XING',
    WELCOME_STARTPAGE_OG_TITLE: 'XING – Ideas for a new world of work',
    WELCOME_STARTPAGE_PROCEED_WITH_APPLE_BUTTON: 'Continue with Apple',
    WELCOME_STARTPAGE_PROCEED_WITH_GOOGLE_BUTTON: 'Continue with Google',
    WELCOME_STARTPAGE_XINGGUIDE_BUTTON: 'Learn more',
    WELCOME_STARTPAGE_XINGGUIDE_IMG_ALT:
      'A message from the XING Guide which recommends a job ad',
    WELCOME_STARTPAGE_XINGGUIDE_TEXT:
      "Are you looking for a job or just started a new one? The XING Guide offers you advice on how to showcase yourself on XING. You'll also receive suggested contacts and articles worth reading.",
    WELCOME_STARTPAGE_XINGGUIDE_TITLE: 'Your professional companion',
    WELCOME_TOGGLE_FORM_BUTTON_LOGIN: 'Log in',
    WELCOME_TOGGLE_FORM_BUTTON_REGISTER: 'Register',
    XAM_GENERIC_ERROR_MESSAGE: 'Sorry, something went wrong. Please try again.',
    XAM_LEAD_AD_CUSTOM_QUESTION_PLACEHOLDER: 'Please enter your reply here.',
    XAM_LEAD_AD_FORM_THANKS_CTA_DOWNLOAD_NOW: 'Download now',
    XAM_LEAD_AD_FORM_THANKS_CTA_TRY_NOW: 'Try now',
    XAM_LEAD_AD_FORM_THANKS_CTA_VISIT_COMPANY_WEBSITE: 'Visit company website',
    XAM_LEAD_AD_FORM_THANKS_CTA_VISIT_NOW: 'Visit now',
    XAM_LEAD_AD_FORM_THANKS_CTA_VISIT_XING_PAGE: 'Visit XING page',
    XAM_LEAD_AD_PREVIEW_CONFIRMATION_SUCCESS_MESSAGE: 'Request sent',
    XAS_ARMSTRONG_LEAD_AD_FORM_THANKS_BUTTON_COPY: 'View XING Page',
    XDS_EMPTYSTATE_ERROR_BUTTON: 'Try again',
    XDS_EMPTYSTATE_ERROR_DESCRIPTION: 'Please try again.',
    XDS_EMPTYSTATE_ERROR_TITLE: "That didn't work.",
  },
};
